<template>
  <div>
    <div v-if="this.$route.name === 'ActivityLog'">
      <ActivityLog />
    </div>
  </div>
</template>

<script>
import ActivityLog from "@/views/companyApp/activityLog/ActivityLog.vue";

export default {
  name: "ActivityLogParent",
  components: {
    ActivityLog,
  },
};
</script>