<template>
  <v-container class="container--fluid grid-list-md">
    <v-row no-gutters>
      <v-col cols="12" class="my-2">
        <span
          class="font-weight-medium text-body-1 primary--text text--darken-1"
          >{{ $t("activityLog.activityLogList") }}</span
        >
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <ActivityLogTable :listSkeleton.sync="listSkeleton" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ActivityLogTable from "@/components/activityLog/Tables/ActivityLogTable.vue";

export default {
  name: "ActivityLog",
  components: {
    ActivityLogTable,
  },
  data() {
    return {
      listSkeleton: true,
    };
  },
};
</script>